.container {
	// z-index: 90;

	// &.zIndex {
	// 	z-index: 10000;
	// }
	.backdrop {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
		visibility: hidden;
		z-index: 100;
		transition: all 0.2s;
		height: 100vh;
		width: 100vw;
		&.show {
			visibility: visible;
			opacity: 1;
			background: rgba(0, 0, 0, 0.15);
		}
		&:not(.show) {
			user-select: none;
			pointer-events: none;
		}
	}
	.searchBox {
		position: relative;
		z-index: 101;
		svg {
			position: absolute;
			left: 11px;
			top: 11px;
			// color:#777;
		}
	}
	.input {
		border-radius: 5px;
		padding-left: 36px;
		box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
		border: 0;
		height: 40px;
		&.open {
			border-radius: 5px 5px 0 0;
		}
	}
	margin-bottom: 10px;
	.searchBarContainer {
		position: relative;
		z-index: 100;

		.searchBarBox {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			// padding: 10px;
			background-color: #fff;
			border-radius: 0 0 5px 5px;
			border: 1px solid #eee;
			box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.15);
			max-height: 70vh;
			min-height: 50vh;
			overflow-y: auto;
		}
	}
}
.notfound {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.notfoundImage {
		text-align: center;
		img {
			width: 50%;
			opacity: 30%;
		}
	}
	.notfoundText {
	}
}

.closeIcon {
	position: absolute;
	top: 5px;
	right: 5px;
	color: #777;
	z-index: 10;
	// box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
	background-color: transparent;
	border: 0;
	border-radius: 10px;
	font-size: 20px;
	span {
		// color:#ff5252 ;
	}
}
