.phoneNumber {
	z-index: 10;
	input {
		outline: none;
	}
}

.inputs {
	width: 100%;
	max-width: 100%;
	position: relative;
	gap: 7.8px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	// margin: 21px 0px 0px 12px;
	padding: 20px 10px;
	// overflow: hidden;
	// box-sizing: border-box;
	// margin: 21px;
	// background-color: #ffffff;
}
// .onlinePayment {
// 	// margin-top: 40px;
// 	padding: 0 0 0;
// 	justify-content: center;
// 	gap: 3px;
// 	flex-wrap: wrap;
// 	> img {
// 		// width: 20%;
// 		max-height: 20px;
// 		// height: 20px;
// 		// max-width: 20%;
// 		// width: 20%;
// 	}
// }
// .onlinePaymentPadding {
// 	padding: 8px 27px 10px;
// 	.powerd {
// 		width: 100%;
// 	}
// }
// .onlinePercent {
// 	right: 0;
// 	// position: absolute;
// 	display: flex;
// 	justify-content: end;

// 	img {
// 		width: 35%;
// 		margin-top: -15%;
// 		margin-right: -12%;
// 	}
// }
.onlinePayment {
	// margin-top: 40px;
	padding: 0 0 0;
	margin-top: 20px;

	justify-content: center;
	gap: 3px;
	flex-wrap: wrap;
	align-items: center;
	> img {
		// width: 20%;
		// max-height: 20px;
		// height: 20px;
		max-width: 32px;
		// width: 20%;
	}
}
.onlinePaymentPadding {
	padding: 8px 10px 10px;
}
.powerd {
	&:not(.footer) {
		width: 50%;
		margin-bottom: 10px;
	}
	&.footer {
		max-width: 70px;
	}
}
.onlinePercent {
	right: -7px;
	position: absolute;
	display: flex;
	justify-content: end;
	z-index: 5;
	top: -19px;
	// margin-bottom: 10px;

	img {
		// width: 32%;
		width: 60px;
		// margin-top: -15%;
		// margin-right: -12%;
	}
}
.buttonFree {
	border-width: 1px;
	border-radius: 10px;
	border-style: solid;
	border-color: #fc7b7b;
	box-sizing: border-box;
	background-color: #ffffff;
}
.govUkIcon {
	width: 30px;
}
.inputEndOf {
	padding: 20px;
	z-index: 10;
}
